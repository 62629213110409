import {ILernpfad} from "../../interfaces/ILernpfad";

import styles from "./LernpfadHeader.module.scss";
import chevronLeft from "../../assets/chevron-left.svg";
import {Nugget} from "../atoms/Nugget";

export interface ILernpfadHeaderProps {
  lernpfad?: ILernpfad;
  onBackClick: () => void;
}

export const LernpfadHeader = (props: ILernpfadHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.backButtonContainer} onClick={props.onBackClick}>
        <div className={styles.backButtonImage}>
          <img
            src={chevronLeft}
            alt="Pfeil links"
            className={styles.image}
          ></img>
        </div>
        <div>Zurück zur Übersicht</div>
      </div>
      <div className={styles.title}>{props.lernpfad?.titel}</div>
      <div className={styles.block}>
        <Nugget
          numOfContents={props.lernpfad?.anzahlInhalte ?? 0}
          coffeeSize={props.lernpfad?.groesse ?? "klein"}
        ></Nugget>
      </div>
    </div>
  );
};
