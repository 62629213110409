import {Route, createRoutesFromElements} from "react-router-dom";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import styles from "./App.module.scss";
import {Overview} from "./pages/Overview";
import {Login} from "./pages/Login";
import {Lernpfad} from "./pages/Lernpfad";
import {RootLayout} from "./pages/RootLayout";
import {IAppConfig} from "./interfaces/IAppConfig";
import {useDispatch} from "react-redux";
import {setApiUrl, setLizenz} from "./state/systemSlice";
import {DemoExpired} from "./pages/DemoExpired";
import {ProtectedLayout} from "./pages/ProtectedLayout";
import {ILizenz} from "./interfaces/ILizenz";

export interface AppProps {
  appConfig: IAppConfig;
  lizenz: ILizenz | null;
}

function App(props: AppProps) {
  const dispatch = useDispatch();

  dispatch(setApiUrl(props.appConfig.apiUrl));
  dispatch(setLizenz(props.lizenz));

  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route element={<RootLayout />}>
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<Overview />} />
          <Route path="/lernpfad/:id" element={<Lernpfad />} />
        </Route>
        <Route path="/expired" element={<DemoExpired />} />
      </Route>,
      <Route path="/login" element={<Login />} />,
    ])
  );

  return (
    <div className={styles.app}>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
