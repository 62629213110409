import styles from "./ExternalLinkCard.module.scss";

export interface Props {
  url: string;
  type: "quiz" | "faq";
}

export const ExternalLinkCard = (props: Props) => {
  return (
    <div className={styles.container}>
      <div
        className={
          props.type === "quiz" ? styles.cuppyBrain : styles.wanderingCuppy
        }
      ></div>

      <div className={styles.heading}>
        {props.type === "quiz" ? "Teste dein Wissen" : "FAQ"}
      </div>
      <div className={styles.text}>
        {props.type === "quiz"
          ? "Das Quiz wurde mit Google Forms erstellt und erfordert das Öffnen eines externen Links."
          : "Der Link wird in einem neuen Tab in Deinem Browser geöffnet."}
      </div>

      <a
        href={props.url}
        className={styles.button}
        target="_blank"
        rel="noreferrer"
      >
        {props.type === "quiz" ? "Quiz öffnen" : "Link öffnen"}
      </a>
    </div>
  );
};
