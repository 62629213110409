import styles from "./LerninhaltControls.module.scss";
import chevronLeft from "../../assets/chevron-left.svg";
import chevronRight from "../../assets/chevron-right.svg";
import normalScreenIcon from "../../assets/normal-screen.svg";
import fullScreenIcon from "../../assets/full-screen.svg";
import {useState} from "react";
import {useKeys} from "../../hooks";

export interface LerninhaltControlsProps {
  totalNum: number;
  currentNum: number;
  handleBackClicked: (newCurrentNum: number) => void;
  handleNextClicked: (newCurrentNum: number) => void;
  handleFullScreenClicked: (fullScreen: boolean) => void;
}

export const LerninhaltControls = (props: LerninhaltControlsProps) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const handleBackClicked = () => {
    if (props.currentNum - 1 > 0) {
      props.handleBackClicked(props.currentNum - 1);
    }
  };
  const handleNextClicked = () => {
    if (props.currentNum + 1 <= props.totalNum) {
      props.handleNextClicked(props.currentNum + 1);
    }
  };
  const handleFullScreenClicked = () => {
    setFullScreen((prevState) => !prevState);
    props.handleFullScreenClicked(!fullScreen);
  };

  useKeys('Escape', () => {
    setFullScreen(false);
    props.handleFullScreenClicked(false);
  });

  document.onfullscreenchange = (e) => {
    if(!document.fullscreenElement && fullScreen) {
      setFullScreen(false);
      props.handleFullScreenClicked(false);
    }
  };

  useKeys('f', () => {
    setFullScreen(true);
    props.handleFullScreenClicked(true);
  })

  useKeys('ArrowLeft', handleBackClicked);
  useKeys('ArrowRight', handleNextClicked);

  return (
    <div className={styles.container}>
      {props?.totalNum > 1  && (
        <>
          <div className={styles.button} onClick={() => handleBackClicked()}>
            <img src={chevronLeft} alt="Pfeil links" className={styles.image}></img>
          </div>
          <div>
            {props.currentNum} / {props.totalNum}
          </div>
          <div className={styles.button} onClick={() => handleNextClicked()}>
            <img
              src={chevronRight}
              alt="Pfeil rechts"
              className={styles.image}
            ></img>
          </div>
          <div>|</div>
        </>
      )}
      <div className={styles.button} onClick={() => handleFullScreenClicked()}>
        <img
          src={fullScreen ? normalScreenIcon : fullScreenIcon}
          alt={fullScreen ? 'Normalbild' : 'Vollbild'}
          className={styles.image}
        ></img>
      </div>
    </div>
  );
};
