import axios from "axios";
import store from "../state/store";
import { ILizenz } from "../interfaces/ILizenz";

export class AuthenticationService {
  public static instance: AuthenticationService = new AuthenticationService();

  public getLizenzFromBaseUrlByToken(baseUrl: string, token?: string): Promise<ILizenz | null> {
    if(baseUrl === null || !token)
      return Promise.resolve(null);
    
    return this.getLizenz(baseUrl, {token});
  }

  public getLizenzByAccessCode(lizenzKey: string): Promise<ILizenz | null> {
    let baseUrl = store.getState().system.apiUrl;

    return this.getLizenz(baseUrl, {lizenzKey});
  }

  private getLizenz(baseUrl: string, requestBody:{token?:string, lizenzKey?: string}): Promise<ILizenz | null> {
    const data = axios
      .post(`${baseUrl}/login`, requestBody)
      .then<ILizenz>((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
    return data;
  }
}