import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILernpfad } from '../interfaces/ILernpfad';
import { ILerninhalt } from '../interfaces/ILerninhalt';

export interface LernpfadState {
  lernpfad?: ILernpfad;
  lerninhalt?: ILerninhalt;
  fullScreen?: boolean;
}

const initialState: LernpfadState = {
  lernpfad: undefined,
  lerninhalt: undefined
};

const lernpfadSlice = createSlice({
  name: 'lernpfad',
  initialState,
  reducers: {
    setLernpfad(state, action: PayloadAction<ILernpfad>) {
      state.lernpfad = action.payload;
    },
    setLerninhalt(state, action: PayloadAction<ILerninhalt>) {
      state.lerninhalt = action.payload;
    }
  }
})

export const { setLernpfad, setLerninhalt} = lernpfadSlice.actions;
export default lernpfadSlice.reducer;
