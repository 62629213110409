import axios from "axios";
import { IAppConfig } from "../interfaces/IAppConfig";

export class AppConfigService {
  public static instance: AppConfigService = new AppConfigService();

  public getConfig(): Promise<IAppConfig> {
    let baseUrl = window.location.href.indexOf('://localhost') > -1 ? 'https://func-kp-dev-fe.azurewebsites.net/' : '/';
    let configUrl = `${baseUrl}api/v1/config`;

    const data = axios
      .get(configUrl)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  }
}
