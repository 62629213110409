import {useState, useEffect, Component} from "react";
import {Navigate, Outlet, Route, useLocation} from "react-router-dom";

import {AuthenticationService} from "../services/AuthenticationService";
import store from "../state/store";
import { useSelector } from "react-redux";


export const ProtectedLayout = () => {
  const location = useLocation();
  //const lizenz = store.getState().system.lizenz;
  const lizenz = useSelector(() => store.getState().system.lizenz);

  return (
    <>
      { (!lizenz || !lizenz.token) && <Navigate to="/login" replace state={{from: location}} />}
      { lizenz && lizenz.token && !lizenz.lizenzAktiv && <Navigate to="/expired" replace state={{from: location}} />}
      { lizenz && lizenz.token && lizenz.lizenzAktiv && <Outlet /> }
    </>
  )
};