import {useCallback, useEffect} from "react";

const KEY_EVENT_TYPE = 'keyup';

export const useKeys = (key: string, handleKeyPressed: () => void): void => {
  const handleKey = useCallback((event: any): void => {
    if (event.key === key) {
      handleKeyPressed();
    }
  }, [handleKeyPressed]);

  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleKey, false);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleKey, false);
    };
  }, [handleKey]);
}
