import {Outlet} from "react-router";
import styles from "./RootLayout.module.scss";
import {ContextMenuButton} from "../components/atoms/ContextMenuButton";

export const RootLayout = () => {
  // const [menuOpen, setMenuOpen] = useState(false);
  // const toggleMenu = (e: any) => {
  //   e.preventDefault();
  //   setMenuOpen(!menuOpen);
  // };
  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.contentArea}>
          <div className={styles.logoHeader}></div>
          <div className={styles.wrapper}>
            <ContextMenuButton></ContextMenuButton>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};
