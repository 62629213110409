import {useEffect, useRef, useState} from "react";
import {ILerninhalt} from "../../interfaces/ILerninhalt";
import styles from "./LerninhaltViewer.module.scss";
import {LerninhaltControls} from "../atoms/LerninhaltControls";
import {ExternalLinkCard} from "./ExternalLinkCard";
import {useDispatch, useSelector} from "react-redux";
import {setFullScreen} from "../../state/systemSlice";
import { DemoWatermark } from "../atoms/DemoWatermark";

export interface ILerninhaltViewerProps {
  lerninhalt?: ILerninhalt;
}

export const LerninhaltViewer = (props: ILerninhaltViewerProps) => {
  // TODO: guess we need some kind of factory for this where a component is chosen based on the lerninhalt.typ
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");
  const [currentAssetIndex, setCurrentAssetIndex] = useState(1);
  const fullScreen = useSelector((state:any) => state.system.fullScreen);
  const contentContainer = useRef<HTMLDivElement>(null);

  const handleBackClicked = (newCurrentAsset: number) => {
    setCurrentAssetIndex(newCurrentAsset);
  };
  const handleNextClicked = (newCurrentAsset: number) => {
    setCurrentAssetIndex(newCurrentAsset);
  };
  const handleFullScreenClicked = (fullScreen: boolean): void => {
    dispatch(setFullScreen(fullScreen));

    if(document.fullscreenElement) {
      document.exitFullscreen();
    }

    if(fullScreen) {
      contentContainer.current?.requestFullscreen();
    }
  }


  useEffect(() => {
    setCurrentAssetIndex(1);
  }, [props.lerninhalt]);

  useEffect(() => {
    let newUrl = props.lerninhalt?.assets[currentAssetIndex - 1]?.url || "";
    setUrl(newUrl);
  }, [props.lerninhalt, currentAssetIndex]);

  const video = url && (
    <video
      controls
      controlsList="nodownload"
      disablePictureInPicture 
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      key={`${url}_${Date.now()}`}
      className={styles.video}
    >
      <source src={url}></source>
    </video>
  );

  const image = url && (
    <img
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      id="image"
      src={url}
      alt=""
      className={`${styles.contentImage} ${fullScreen ? styles.fullScreen : ''}`}
    ></img>
  );

  const quiz = (
    <div className={styles.externalLink}>
      <ExternalLinkCard url={url} type={"quiz"}></ExternalLinkCard>
    </div>
  );

  const faq = (
    <div className={styles.externalLink}>
      <ExternalLinkCard url={url} type={"faq"}></ExternalLinkCard>
    </div>
  );

  let content;
  let useWatermark = false;
  let isImage = false;
  switch (props.lerninhalt?.typ) {
    case "Video":
      content = video;
      break;
    case "Checkliste":
    case "Intro":
    case "Infografik":
    case "Schritt-für-Schritt-Anleitung":
      content = image;
      isImage = true;
      useWatermark = true;
      break;
    case "Quiz":
      // case "Link":
      content = quiz;
      break;
    case "FAQ":
      content = faq;
      break;
    default:
      content = video;
  }

  return (
    <div className={`${styles.container} ${fullScreen ? styles.fullScreen : ''}`} ref={contentContainer}>
      <div className={`${styles.content} ${fullScreen ? styles.fullScreen : ''}`}>
        <DemoWatermark active={useWatermark}>{content}</DemoWatermark>
      </div>
      <div className={styles.bottomBar}>
        <div className={styles.title}>{props.lerninhalt?.name}</div>
        {props.lerninhalt && isImage ? (
          <div className={styles.buttonArea}>
            <LerninhaltControls
              totalNum={props.lerninhalt?.assets.length || 2}
              currentNum={currentAssetIndex}
              handleBackClicked={handleBackClicked}
              handleNextClicked={handleNextClicked}
              handleFullScreenClicked={handleFullScreenClicked}
            ></LerninhaltControls>
          </div>
        ) : (
          <div className={styles.buttonsPlaceholder}></div>
        )}
      </div>
    </div>
  );
};
